import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';

import { FaEdit, FaTrash } from 'react-icons/fa';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import useAcodaxNumberFormat from 'hooks/useAcodaxNumberFormat';

function AdditionalInvoiceTable({ data, setFormData, handleEdit }) {
  const acodaxNumberFormat = useAcodaxNumberFormat();
  const Translate = useAxisproTranslate();

  const handleDeleteLineItem = index => {
    if (index > -1 && index < data.length) {
      data.splice(index, 1);
    }
    setFormData(prev => ({
      ...prev,
      details: data
    }));
  };

  return (
    <div className="mb-0">
      <Table
        striped
        bordered
        responsive
        className="form-table-layout mb-1 simple-table-animation"
      >
        <thead className="text-white bg-dark ">
          <tr>
            <th
              className="border-end p-1 ps-2 text-center"
              style={{ width: '120px' }}
            >
              {Translate('STOCK ID')}
            </th>
            <th className="border-end p-1 ps-2">{Translate('ITEM NAME')}</th>
            <th
              className="border-end p-1 ps-2 text-center"
              style={{ width: '130px' }}
            >
              {Translate('QUANTITY')}
            </th>
            <th
              className="border-end p-1 ps-2 text-center"
              style={{ width: '130px' }}
            >
              {Translate('AMOUNT')}
            </th>
            <th
              className="border-end p-1 ps-2 text-center"
              style={{ width: '100px' }}
            >
              {Translate('ACTION')}
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 &&
            data.map((items, index) => {
              return (
                <tr key={index} className="text-dark">
                  <td className="text-center">{items?.stock_id}</td>
                  <td className="d-flex flex-column">
                    <span>{items?.item_display_name}</span>
                    {items?.description ? (
                      <span className="ms-3">
                        -
                        <span className="text-muted ms-2">
                          {items?.description}
                        </span>
                      </span>
                    ) : (
                      ''
                    )}
                  </td>
                  <td className="text-center">
                    {acodaxNumberFormat(items?.quantity)}
                  </td>
                  <td className="text-center">
                    {acodaxNumberFormat(items?.unit_price)}
                  </td>
                  <td className=" text-center">
                    <FaEdit
                      className="me-2 cursor-pointer"
                      color="#2c7be5"
                      onClick={() => handleEdit(index)}
                    />
                    <FaTrash
                      className="text-danger cursor-pointer"
                      onClick={() => handleDeleteLineItem(index)}
                    />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
}

AdditionalInvoiceTable.propTypes = {
  data: PropTypes.array,
  setFormData: PropTypes.func,
  handleEdit: PropTypes.func,
  editId: PropTypes.string
};

export default AdditionalInvoiceTable;
