import { React, useState, useEffect, useRef } from 'react';
import {
  createSearchParams,
  useParams,
  useSearchParams
} from 'react-router-dom';
import axios from 'axios';
import { Card, Col, Row } from 'react-bootstrap';
import qs from 'qs';
import SimpleBar from 'simplebar-react';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import { showToast } from 'module/Common/Toast/toast';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import ListLoading from 'module/Common/Loading/ListLoading';
import ListHeader from 'module/Common/ListHeader';
import EntryItem from 'module/Common/EntryItem';
import AppPagination from 'components/common/app-pagination/AppPagination';
import DownKeyController from 'module/Common/ArrowKeyController/DownKeyController';
import ResizeWindow from 'module/Common/ResizeWindow/ResizeWindow';
import FormattedData from '../../../Common/TableFilter/FormatedData';
import useRemoveParams from 'module/Common/Hooks/useRemoveParams';
import ApprovalSectionTab from 'components/approval-component/tab/ApprovalSectionTab';
import RentalContractInfo from '../components/RentalContractInfo';

function RentalContractView() {
  const focusRef = useRef();
  const Translate = useAxisproTranslate();
  const { rentalId } = useParams();
  const [queryParams] = useSearchParams();
  const [keyDownActive, setKeyDownActive] = useState(false);
  const pageNumber = queryParams.get('page');
  const info_filter = queryParams.get('info_filter');
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const [paginationData, setPaginationData] = useState([]);
  const filterItems = queryParams.get('filters');
  const allQueryParams = Object.fromEntries([...queryParams]);
  const permission = {
    create_si: getAcodaxPermissionSlug('SI', 'create')
  };
  const entryId =
    info_filter === 'true'
      ? listData[0]?.id
      : rentalId
      ? rentalId
      : listData[0]?.id;

  const [colSize, setColSize] = useState((window.innerWidth / 12) * 2.6);
  const [holdSize, setHoldSize] = useState(false);
  const fetchData = async () => {
    setLoading(true);
    await axios
      .get('/sales/rental-contracts', {
        params: {
          page: pageNumber,
          ...FormattedData(JSON.parse(filterItems))
        },
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(res => {
        if (res.data.success === true) {
          if (res.data.data.data !== '') {
            setListData(res.data.data.data);
            setPaginationData(res.data.data);
            setLoading(false);
            // setRendering(true);
            if (filterItems && res.data.data.data <= 0) {
              showToast('No Records', 'error');
            }
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, [pageNumber, filterItems]);

  useEffect(() => {
    focusIn();
  }, [document.querySelector('.active-tab'), rentalId]);

  const focusOut = () => {
    focusRef.current = null;
    setKeyDownActive(false);
  };
  const focusIn = () => {
    focusRef.current = document.querySelector('.active-tab');
    focusRef.current?.focus();
    if (document.activeElement === focusRef.current) {
      setKeyDownActive(true);
    }
  };
  const containerStyle = {
    width: holdSize ? `calc(100% - ${colSize}px)` : ''
  };
  return (
    <Row className="flex-fill mb-3">
      <ResizeWindow
        holdSize={holdSize}
        setHoldSize={setHoldSize}
        colSize={colSize}
        setColSize={setColSize}
      >
        <Card id="focusDiv" className="p-0 flex-fill mb-0">
          {keyDownActive && (
            <DownKeyController
              url="/fixed-assets/rental-contracts/info/"
              data={listData}
              currentData={entryId}
              focusRef={focusRef}
              active={document.activeElement}
              pageNumber={pageNumber ?? 0}
              lastPage={paginationData?.meta?.last_page}
            />
          )}
          <Card.Header
            className="p-3"
            style={{ backgroundColor: 'whitesmoke' }}
            onClick={focusOut}
          >
            <ListHeader
              moduleName="rental_contracts"
              title={Translate('Rental Contract List')}
              route={'/fixed-assets/rental-contracts/add'}
              refresh={fetchData}
              filter={false}
              showFilter={true}
              filterUrl={'/fixed-assets/rental-contracts/info'}
              permission={permission?.create_si}
              customer
            />
          </Card.Header>
          <ApprovalSectionTab data={paginationData} info={true} />
          <Card.Body onClick={focusIn} className="m-0 p-0">
            {loading ? (
              <ListLoading />
            ) : (
              <>
                <SimpleBar className="simplebar-style-for-three-column-layout">
                  {listData && listData.length > 0 ? (
                    listData.map((item, key) => (
                      <div key={key}>
                        <EntryItem
                          index={key}
                          item={item}
                          focusRef={focusRef}
                          active={
                            entryId
                              ? item.id === entryId
                                ? true
                                : false
                              : item.id === listData[0].id
                              ? true
                              : false
                          }
                          pageNumber={pageNumber}
                          defaultRoute={`/fixed-assets/rental-contracts/info/${
                            item.id
                          }?${createSearchParams(
                            useRemoveParams(allQueryParams, ['info_filter'])
                          )}`}
                        />
                      </div>
                    ))
                  ) : (
                    <div
                      className=" d-flex align-items-center justify-content-center "
                      style={{ height: '700px' }}
                    >
                      {' '}
                      No Records! 🙂
                    </div>
                  )}
                </SimpleBar>
                <Card.Footer className="border-top">
                  {paginationData && paginationData.links && (
                    <AppPagination
                      data={paginationData}
                      url={'/fixed-assets/rental-contracts/info'}
                      search={allQueryParams}
                    />
                  )}
                </Card.Footer>
              </>
            )}
          </Card.Body>
        </Card>
      </ResizeWindow>
      <Col
        sm={12}
        md={9}
        className="d-md-flex flex-column ps-sm-3 ps-md-0"
        onClick={focusOut}
        style={containerStyle}
      >
        <RentalContractInfo
          rentalId={rentalId ?? entryId}
          getData={fetchData}
        />
      </Col>
    </Row>
  );
}

export default RentalContractView;
