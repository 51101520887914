import { React } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { BsCalendar2Date, BsCoin } from 'react-icons/bs';

import LineItemTable from '../Common/Tables/LineItemTable';
import ShowCustomFieldValues from '../Common/ViewPageDetails/ShowCustomFieldValues';
import PersonName from '../Common/ViewPageDetails/PersonName';
import Badges from '../Common/ViewPageDetails/Badges';
import Details from '../Common/ViewPageDetails/Details';
import ShowMemo from '../Common/ViewPageDetails/ShowMemo';
import { FaIdCardClip } from 'react-icons/fa6';

function RentalContractView({ rentalContractData }) {
  return (
    <Container className="mt-3 ps-3 pe-3 voucher-inquiry-view-wrapper">
      <Row>
        <Col lg={6} md={6} sm={12} className="p-0">
          {rentalContractData?.customer_name ? (
            <PersonName value={rentalContractData?.customer_name} />
          ) : null}
          {rentalContractData?.reference && (
            <Badges label={rentalContractData?.reference} bg={'danger'} />
          )}
          <div className="mt-2">
            {rentalContractData?.title && (
              <Details
                label={'Title'}
                value={rentalContractData?.title}
                className={'mb-2 fs--1'}
                iconColor={'text-info'}
              />
            )}
            {rentalContractData?.contract_type && (
              <Details
                label={'Contract Type'}
                value={rentalContractData?.contract_type}
                className={'mb-2 fs--1 text-capitalize'}
                iconColor={'text-danger'}
              />
            )}
            {rentalContractData?.contract_type && (
              <Details
                label={'Contract Tenure'}
                value={rentalContractData?.contract_tenure}
                className={'mb-2 fs--1 text-capitalize'}
                iconColor={'text-success'}
                icon={<FaIdCardClip className="me-1 text-success" size={15} />}
              />
            )}
            {rentalContractData?.contract_from_date_formatted && (
              <Details
                label={'Start Date'}
                value={rentalContractData?.contract_from_date_formatted}
                icon={<BsCalendar2Date className="me-1 text-dark" size={15} />}
                className={'mb-2 fs--1'}
              />
            )}
            {rentalContractData?.contract_to_date_formatted && (
              <Details
                label={'End Date'}
                value={rentalContractData?.contract_to_date_formatted}
                icon={<BsCalendar2Date className="me-1 text-dark" size={15} />}
                className={'mb-2 fs--1'}
              />
            )}
            {rentalContractData?.sales_type && (
              <Details
                label={'Price List'}
                value={rentalContractData?.sales_type}
                className={'mb-2 fs--1'}
                iconColor={'text-warning'}
              />
            )}
          </div>
        </Col>
        <Col lg={6} md={6} sm={12} className="p-0">
          <div className="mt-sm-2 mt-md-5">
            <Details
              label={
                rentalContractData?.tax_included &&
                rentalContractData?.tax_included === '1'
                  ? 'Amount (Inc.Tax)'
                  : 'Amount'
              }
              value={rentalContractData?.amount_formatted ?? '0.00'}
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
            <Details
              label={'Total Tax'}
              value={rentalContractData?.total_tax_formatted ?? '0.00'}
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
            <Details
              label={'Total Discount'}
              value={
                rentalContractData?.total_discount_amount_formatted ?? '0.00'
              }
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
          </div>
        </Col>
      </Row>
      {rentalContractData?.custom_field_values &&
        rentalContractData?.custom_field_values.length > 0 && (
          <Row className="mt-3">
            {rentalContractData?.custom_field_values.map((item, index) => (
              <ShowCustomFieldValues key={index} data={item} />
            ))}
          </Row>
        )}
      <Row>
        <Col xl={12} md={12} sm={12} className="p-0 mt-2">
          <LineItemTable data={rentalContractData} hideViewItemOption />
        </Col>
      </Row>
      {rentalContractData?.description && (
        <ShowMemo
          memo={rentalContractData?.description}
          label={'Description'}
        />
      )}
    </Container>
  );
}

RentalContractView.propTypes = {
  rentalContractData: PropTypes.any
};

export default RentalContractView;
