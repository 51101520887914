import { React, useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Card, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { FcUpload } from 'react-icons/fc';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import FileController from 'components/file-controller/FileController';
import generateFormDataHelper from 'helpers/generateFormDataHelper';
import setNewFormData from 'helpers/setNewFormData';

function FileUpload({ show, onHide, type, id, fetchData, setKey, label }) {
  const Translate = useAxisproTranslate();
  const [save, setSave] = useState(false);
  const [formData, setFormData] = useState({
    type: type,
    id: id,
    files: []
  });
  const handleFieldChange = (e, action) =>
    setFormData(generateFormDataHelper(e, action, formData));

  const handleFiles = files => {
    handleFieldChange({ target: { name: 'files', value: files } });
  };

  let newFormData = setNewFormData(formData);

  const submitFiles = () => {
    setSave(true);
    axios({
      method: 'post',
      url: 'documents',
      data: newFormData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(res => {
        if (res.data.success === true) {
          fetchData();
          onHide();
          setFormData({ ...formData, files: [] });
          setSave(false);
          setKey('attachments');
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (type == 'ITEM') {
      setFormData({ ...formData, collection: 'item-images' });
    }
  }, [type]);

  return (
    <Modal show={show} size="lg">
      <Card>
        <Card.Header className="d-flex gap-2 border-bottom align-items-center justify-content-start">
          <FcUpload size={30} />
          <h5 className="fs--1 text-uppercase text-dark fw-bold m-0">
            {Translate(label ? label : 'Upload Documents')}
          </h5>
        </Card.Header>
        <Card.Body className="pt-0 ps-4 d-flex align-items-center justify-content-start">
          <FileController
            limit={10}
            limitFeature={true}
            limitFileSizeInMB={5}
            limitFileType={[
              'jpg',
              'png',
              'jpeg',
              'gif',
              'pdf',
              'excel',
              'xlsx',
              'docx',
              'doc'
            ]}
            onChange={handleFiles}
            className="my-3"
            note={Translate('You can upload a maximum of 10 files, 5MB each')}
          />
        </Card.Body>
        <Card.Footer className="d-flex gap-2 align-items-center justify-content-end">
          {formData.files.length > 0 ? (
            <Button
              variant="success"
              style={{ boxShadow: 'none' }}
              onClick={submitFiles}
              size="sm"
              disabled={save}
            >
              {save ? 'Uploading...' : 'Upload'}
            </Button>
          ) : (
            ''
          )}

          <Button
            variant="danger"
            style={{ boxShadow: 'none' }}
            onClick={onHide}
            size="sm"
            disabled={save}
          >
            {Translate('Cancel')}
          </Button>
        </Card.Footer>
      </Card>
    </Modal>
  );
}

FileUpload.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  fetchData: PropTypes.func,
  type: PropTypes.any,
  id: PropTypes.any,
  setKey: PropTypes.func,
  label: PropTypes.string
};

export default FileUpload;
