import { React, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import LoadingScreenCover from 'components/common/loading-screen/LoadingScreenCover';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import RentalContractTabView from 'module/Common/ViewPages/RentalContract/RentalContractTabView';
import commonFunction from '../commonFunction';

function RentalContractInfo({
  rentalId,
  offCanvas,
  setShowVoucherDetailArea,
  voidTransactionReport,
  getData
}) {
  const [data, setData] = useState([]);
  const { fetchData } = commonFunction;
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(false);
  const getRentalContract = async () => {
    await fetchData(
      voidTransactionReport,
      rentalId,
      setLoading,
      setData,
      setContentLoading
    );
  };
  useEffect(() => {
    let timeoutId;
    if (rentalId) {
      setLoading(true);
      timeoutId = setTimeout(() => {
        getRentalContract();
      }, 400);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [rentalId]);

  return (
    <>
      {loading && data ? (
        <LoadingScreenCover>
          <LoadingScreen message="Looking for details" />
        </LoadingScreenCover>
      ) : (
        <RentalContractTabView
          rentalContractData={data}
          loading={loading}
          fetchData={getRentalContract}
          setShowVoucherDetailArea={setShowVoucherDetailArea}
          offCanvas={offCanvas}
          voidTransactionReport={voidTransactionReport}
          getData={getData}
          contentLoading={contentLoading}
        />
      )}
    </>
  );
}

RentalContractInfo.propTypes = {
  rentalId: PropTypes.string,
  rendering: PropTypes.bool,
  offCanvas: PropTypes.bool,
  setShowVoucherDetailArea: PropTypes.func,
  voidTransactionReport: PropTypes.bool,
  getData: PropTypes.func,
  contentLoading: PropTypes.bool
};

export default RentalContractInfo;
