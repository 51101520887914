import { React, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import CustomerDetails from 'components/customer-details/CustomerDetails';
import ReactSelectAddButton from 'components/add-button/ReactSelectAddButton';
import CustomerPopupForm from './popup-forms/CustomerPopupForm';
import useAutoCompleteApiCallHandler from 'hooks/useAutoCompleteApiCallHandler';

const SelectCustomer = ({
  handleFieldChange,
  name,
  value,
  keyName,
  showCustomerDetails,
  style,
  placeholder,
  classNamePrefix,
  withAddButton,
  includeDetailsKey,
  onFocus,
  error,
  isDisabled
}) => {
  const Translate = useAxisproTranslate();
  const [showFormWindow, setShowFormWindow] = useState(false);
  const [showCustomerDetailsArea, setCustomerDetailsArea] = useState(false);
  // const [preValue, setPreValue] = useState('');
  const {
    fetchData,
    setDefaultResult,
    loading,
    defaultOptions,
    setDefaultOptions
  } = useAutoCompleteApiCallHandler({
    url: 'sales/customerAutocomplete',
    setParams: searchKey => ({ name: searchKey }),
    setOptions: option => setOptionData(option),
    onFocus: onFocus
  });

  const setOptionData = option => ({
    label: option.customer_id + ' - ' + option.name,
    value: keyName ? option[keyName] : option.id
  });

  const permission = {
    show_cs: getAcodaxPermissionSlug('CS', 'show'),
    create_cs: getAcodaxPermissionSlug('CS', 'create')
  };

  // useEffect(() => {
  //   if (value?.label) {
  //     const parts = value.label.split('-');
  //     const result = parts[parts.length - 1].trim();
  //     setPreValue(result);
  //   } else {
  //     setPreValue('');
  //   }
  // }, [value]);

  const handleCustomerDetailsArea = () => {
    setCustomerDetailsArea(!showCustomerDetailsArea);
  };

  return (
    <>
      <AsyncSelect
        // key={preValue}
        cacheOptions
        isLoading={loading}
        loadOptions={fetchData}
        onFocus={setDefaultResult}
        defaultOptions={defaultOptions}
        isClearable
        name={name ?? 'customer'}
        isSelected={true}
        // isOptionSelected={value ? item => item.value == value.value : ''}
        // value={
        //   value ? optionsArray.find(item => item.label == value.label) : ' '
        // }
        value={value ?? ''}
        classNamePrefix={` ${classNamePrefix} app-react-select`}
        className={`${error && 'is-invalid'}`}
        placeholder={placeholder ?? ''}
        onChange={handleFieldChange}
        isDisabled={isDisabled}
        components={
          withAddButton && permission.create_cs
            ? {
                Menu: props => (
                  <>
                    <ReactSelectAddButton
                      props={props}
                      setShowFormWindow={() => setShowFormWindow(true)}
                      title="Add Customer"
                    />
                  </>
                )
              }
            : null
        }
        styles={{
          menuPortal: provided => ({ ...provided, zIndex: 9999 }),
          menu: provided => ({
            ...provided,
            zIndex: 9999,
            fontFamily: 'sans-serif',
            fontSize: '12px',
            borderRadius: '0px',
            textTransform: 'capitalize',
            overflow: 'hidden'
          }),
          control: (base, state) => ({
            ...base,
            boxShadow: state.isFocused ? 0 : 0,
            borderRadius: '0px',
            border: '1px solid #b5b5b5',
            height: 32,
            minHeight: 32,
            ...style
          })
        }}
        // menuPortalTarget={document.body}
        // classNamePrefix={classNamePrefix}
      />
      {showCustomerDetails && value && (
        <>
          {permission?.show_cs ? (
            <Button
              className="d-flex flex-row mt-2 align-items-center p-0"
              variant="white"
              onClick={handleCustomerDetailsArea}
              style={{ boxShadow: 'none' }}
            >
              <FontAwesomeIcon icon="user-circle" size="xs" className="me-1" />
              <span className="small text-primary">
                {Translate('View Customer Details')}
              </span>
            </Button>
          ) : (
            ''
          )}
          <CustomerDetails
            show={showCustomerDetailsArea}
            onHide={handleCustomerDetailsArea}
            value={value}
            showCustomerDetailsArea={showCustomerDetailsArea}
          />
        </>
      )}

      {withAddButton ? (
        <CustomerPopupForm
          name={name ?? 'customer'}
          labelName={keyName ?? 'default'}
          keyName={keyName ?? 'id'}
          show={showFormWindow}
          onHide={() => setShowFormWindow(false)}
          setData={handleFieldChange}
          includeDetailsKey={includeDetailsKey}
          callBack={newItem => {
            if (newItem?.id) {
              setDefaultOptions(prev => [setOptionData(newItem), ...prev]);
            }
          }}
        />
      ) : null}
    </>
  );
};

SelectCustomer.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.string,
  showCustomerDetails: PropTypes.bool,
  value: PropTypes.any,
  keyName: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  classNamePrefix: PropTypes.string,
  withAddButton: PropTypes.bool,
  includeDetailsKey: PropTypes.bool,
  onFocus: PropTypes.func,
  error: PropTypes.bool,
  isDisabled: PropTypes.bool
};

export default SelectCustomer;
