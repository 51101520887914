import { React, useEffect, useState } from 'react';
import { Badge, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import useAcodaxNumberFormat from 'hooks/useAcodaxNumberFormat';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import VoucherDetail from 'components/voucher-details/VoucherDetail';
import PaymentScreenPopup from '../PaymentScreenPopup';
import { MdDoneAll } from 'react-icons/md';

function TransactionTable({ data, enablePaymentOption, fetchData }) {
  const Translate = useAxisproTranslate();
  const acodaxNumberFormat = useAcodaxNumberFormat();
  const [voucherId, setVoucherId] = useState();
  const [transType, setTransType] = useState();
  const [showVoucherDetailArea, setShowVoucherDetailArea] = useState(false);
  const [payment, setPayment] = useState(false);
  const [paymentData, setPaymentData] = useState({
    person: {
      personType: 'CUSTOMER',
      personId: data.customer_id,
      name: data.customer_name,
      value: data.customer_id
    },
    salesInvoiceData: {},
    balance: ''
  });
  const [total, setTotal] = useState({
    amount: 0,
    paid_amount: 0,
    balance_amount: 0
  });

  const clickOnVoucher = (voucher_id, trans_type) => {
    setVoucherId(voucher_id);
    setTransType(trans_type);
    setShowVoucherDetailArea(true);
  };
  const handleVoucherDetailsArea = () => {
    setShowVoucherDetailArea(!showVoucherDetailArea);
  };

  useEffect(() => {
    if (data && data.sales_invoices && data.sales_invoices.length > 0) {
      let amount = data.sales_invoices.map(item => parseFloat(item.amount));
      let paid_amount = data.sales_invoices.map(item => parseFloat(item.alloc));
      let balance_amount = data.sales_invoices.map(item =>
        parseFloat(item.balance)
      );
      if (amount.length > 0) {
        let totalAmount = amount.reduce((a, b) => a + b);
        setTotal(prev => ({
          ...prev,
          amount: totalAmount
        }));
      }
      if (paid_amount.length > 0) {
        let totalPaidAmount = paid_amount.reduce((a, b) => a + b);
        setTotal(prev => ({
          ...prev,
          paid_amount: totalPaidAmount
        }));
      }
      if (balance_amount.length > 0) {
        let totalBalanceAmount = balance_amount.reduce((a, b) => a + b);
        setTotal(prev => ({
          ...prev,
          balance_amount: totalBalanceAmount
        }));
      }
    }
  }, [data]);

  const handleReceivePayment = invoice => {
    setPaymentData(prev => ({
      ...prev,
      salesInvoiceData: invoice,
      balance: invoice?.balance
    }));
    setPayment(true);
  };

  return (
    <>
      <Table
        striped
        bordered
        hover
        responsive
        className="mt-2 simple-table-animation"
      >
        <thead className="bg-dark text-light text-uppercase">
          <tr>
            <th className="fs--2 p-2 text-center">#</th>
            <th className="fs--2 p-2 text-center">{Translate('Date')}</th>
            <th className="fs--2 p-2 text-center">{Translate('Reference')}</th>
            <th className="fs--2 p-2 text-center">
              {Translate('Invoice Amount')}
            </th>
            <th className="fs--2 p-2 text-center">
              {Translate('Paid Amount')}
            </th>
            <th className="fs--2 p-2 text-center">
              {Translate('Balance Amount')}
            </th>
            {enablePaymentOption && (
              <th className="fs--2 p-2 text-center">{Translate('Actions')}</th>
            )}
          </tr>
        </thead>
        {data &&
          data.sales_invoices &&
          data.sales_invoices.map((item, key) => (
            <tbody key={key} className="text-dark">
              <tr>
                <td className="p-2 text-center">{key + 1}</td>
                <th className="p-2 text-center">{item.trans_date_formatted}</th>
                <th className="p-2 text-center">
                  {getAcodaxPermissionSlug(item.trans_type, 'show') ? (
                    <a
                      href="#"
                      onClick={() => clickOnVoucher(item.id, item.trans_type)}
                    >
                      {item.reference}
                    </a>
                  ) : (
                    item.reference
                  )}
                </th>
                <th className="p-2 text-center">{item.amount_formatted}</th>
                <th className="p-2 text-center">{item.alloc_formatted}</th>
                <th className="p-2 text-center">{item.balance_formatted}</th>
                {enablePaymentOption && (
                  <th className="p-2 text-center">
                    {parseFloat(item?.balance) > 0 ? (
                      <Badge
                        style={{ cursor: 'pointer' }}
                        bg="warning"
                        onClick={() => handleReceivePayment(item)}
                        className="pt-1 pb-1"
                      >
                        {Translate('Receive Payment')}
                      </Badge>
                    ) : (
                      <Badge bg="success" className="pt-1 pb-1">
                        <MdDoneAll className="me-1" size={10} />
                        {Translate('Fully Paid')}
                      </Badge>
                    )}
                  </th>
                )}
              </tr>
            </tbody>
          ))}
        <tfoot>
          <tr className="text-dark">
            <td></td>
            <td></td>
            <td className="p-2 fw-bold text-end">{Translate('Total')}</td>
            <td className="p-2 fw-bold text-center">
              {acodaxNumberFormat(total?.amount ?? 0)}
            </td>
            <td className="p-2 fw-bold text-center">
              {acodaxNumberFormat(total?.paid_amount ?? 0)}
            </td>
            <td className="p-2 fw-bold text-center">
              {acodaxNumberFormat(total?.balance_amount ?? 0)}
            </td>
          </tr>
        </tfoot>
      </Table>
      <VoucherDetail
        show={showVoucherDetailArea}
        onHide={handleVoucherDetailsArea}
        setShowVoucherDetailArea={setShowVoucherDetailArea}
        type={transType}
        voucherId={voucherId}
        infoPage={true}
        offCanvas={true}
      />
      <PaymentScreenPopup
        show={payment}
        person={paymentData?.person}
        invoiceData={paymentData?.salesInvoiceData}
        onHide={() => setPayment(false)}
        dueAmount={paymentData?.balance}
        fetchData={() => {
          fetchData();
        }}
      />
    </>
  );
}

TransactionTable.propTypes = {
  data: PropTypes.object,
  enablePaymentOption: PropTypes.bool,
  fetchData: PropTypes.func
};

export default TransactionTable;
