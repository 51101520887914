import { React, useContext } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { AuthWizardContext } from 'context/Context';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';

function PriceInputField({
  label,
  name,
  value,
  error,
  formError,
  handleFieldChange,
  required
}) {
  const { user } = useContext(AuthWizardContext);
  const Translate = useAxisproTranslate();
  const baseCurrency = user?.branch?.base_currency ?? '';

  return (
    <Row className="mb-3">
      <Col
        xl={4}
        md={4}
        sm={12}
        className="d-flex align-items-center justify-content-start ps-0"
      >
        <Form.Label className={required ? 'require-data m-0' : 'm-0'}>
          {Translate(label)}
        </Form.Label>
      </Col>
      <Col xl={8} md={8} sm={12}>
        <Row>
          <Col xl={11} md={11} sm={11}>
            <InputGroup>
              <Form.Control
                type="number"
                name={name}
                onChange={handleFieldChange}
                value={value}
                isInvalid={!!formError[name]}
              />
              <InputGroup.Text
                style={{
                  borderRadius: '0px',
                  backgroundColor: 'whitesmoke'
                }}
              >
                <h5 className="fs--2 m-0">{baseCurrency}</h5>
              </InputGroup.Text>
            </InputGroup>
          </Col>
          <Col
            xl={1}
            md={1}
            sm={1}
            className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
          >
            {error ? (
              <FormErrorPopover
                id="sale_rate"
                totalErrorCount={Object.keys(formError).length}
                errorMessage={error}
              />
            ) : (
              ''
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

PriceInputField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  error: PropTypes.any,
  formError: PropTypes.any,
  handleFieldChange: PropTypes.func,
  required: PropTypes.bool
};

export default PriceInputField;
