import React from 'react';
import PropTypes from 'prop-types';
import {
  createSearchParams,
  useOutletContext,
  useSearchParams
} from 'react-router-dom';
import { FaEdit, FaEye, FaFileInvoice, FaUser } from 'react-icons/fa';
import { Badge, Button, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BsFillTrashFill } from 'react-icons/bs';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AppPagination from 'components/common/app-pagination/AppPagination';
import TableHeader from 'helpers/tableHeader';
import { AiFillCheckCircle } from 'react-icons/ai';
import { FiArrowRight } from 'react-icons/fi';
import AddButton from 'components/add-button/AddButton';
import PrintButton from 'components/print-button/PrintButton';
import ExportButton from 'components/export-button/ExportButton';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import Filter from 'module/Common/TableFilter/Filter';
import { paymentStatus } from 'module/Common/Enum/enum';
import FormattedData from 'module/Common/TableFilter/FormatedData';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import { setCustomFieldItemsTableHeaders } from 'helpers/setCustomFields';
import CommonSearch from 'components/common-search/CommonSearch';

const PurchaseInvoiceTableView = ({
  data,
  handleDelete,
  isLoading,
  isFixedAsset
}) => {
  const Translate = useAxisproTranslate();
  const [permissions] = useOutletContext();
  const [queryParams] = useSearchParams();
  const pageNumber = queryParams.get('page');
  const filterQueryParam = queryParams.get('filters');
  const allQueryParams = Object.fromEntries([...queryParams]);
  const pathPreFix = isFixedAsset ? 'fixed-assets' : 'purchase';
  const titlePreFix = isFixedAsset ? 'Fixed asset p' : 'P';
  const filterModuleNamePreFix = isFixedAsset ? 'fixed_asset_' : '';

  const columns = [
    {
      accessor: 'date',
      Header: Translate('Date'),
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-0'
      },
      Cell: rowData => {
        return (
          <h5 className="mb-0 fs--1">
            {rowData.row.original.trans_date_formatted}
          </h5>
        );
      }
    },
    {
      accessor: 'reference',
      Header: Translate('Reference'),
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-0'
      },
      Cell: rowData => {
        return <h5 className="mb-0 fs--1">{rowData.row.original.reference}</h5>;
      }
    },

    {
      accessor: 'person_name',
      Header: Translate('Supplier / info'),
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-0'
      },
      Cell: rowData => {
        return (
          <>
            {rowData.row.original.supplier_name && (
              <div className="d-flex flex-row mb-2">
                <FaUser />
                <h6 className="mb-0 ms-2 fw-bold">
                  {rowData.row.original.supplier_name}
                </h6>
              </div>
            )}

            {rowData.row.original.from && (
              <div style={{ opacity: 0.9 }} className="d-flex">
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip>
                      <small>
                        {Translate('Converted From Purchase')}
                        {rowData.row.original.from === 'PO'
                          ? ` ${Translate('Order')}`
                          : ` ${Translate('Delivery')}`}
                      </small>
                    </Tooltip>
                  }
                >
                  <div style={{ opacity: 0.9 }}>
                    <Badge
                      bg={
                        rowData.row.original.from === 'PO' ? 'primary' : 'info'
                      }
                      className="text-uppercase p-1 d-flex flex-row align-items-center"
                    >
                      <small>{rowData.row.original.from}</small>
                      <FiArrowRight className="mx-1" />
                      <FaFileInvoice />
                    </Badge>
                  </div>
                </OverlayTrigger>
              </div>
            )}
          </>
        );
      }
    },
    {
      accessor: 'amount',
      Header: Translate('Amount'),
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-0'
      },
      Cell: rowData => {
        return (
          <h5 className="mb-0 fs--1">
            {rowData.row.original.amount_formatted}
          </h5>
        );
      }
    },
    {
      accessor: 'status',
      Header: Translate('Status'),
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-0'
      },
      Cell: rowData => {
        let paymentStatus = rowData.row.original?.payment_status?.toLowerCase(),
          variend;
        switch (paymentStatus) {
          case 'fully paid':
            variend = 'success';
            break;

          case 'partially paid':
            variend = 'warning';
            break;

          default:
            variend = 'danger';
            break;
        }
        return (
          <Badge bg={variend} className="text-uppercase">
            {paymentStatus}
          </Badge>
        );
      }
    },
    ...setCustomFieldItemsTableHeaders({
      customFieldItems: data?.customFields,
      Translate: Translate
    }),
    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'py-0 text-end'
      },
      Cell: rowData => {
        return (
          <div className="d-flex flex-row justify-content-end">
            <OverlayTrigger
              overlay={
                <Tooltip>
                  <small>{Translate('Fully Delivered')}</small>
                </Tooltip>
              }
            >
              <div style={{ opacity: 0.9 }}>
                <Badge bg="transparent" className="text-uppercase p-1">
                  <AiFillCheckCircle size={18} className="text-success" />
                </Badge>
              </div>
            </OverlayTrigger>

            <Link
              to={`/${pathPreFix}/purchase-invoice?${createSearchParams({
                ...(filterQueryParam ? { filters: filterQueryParam } : null),
                entry: rowData.row.original.id,
                layout: 'column',
                ...(pageNumber ? { page: pageNumber } : null)
              })}`}
              className={'p-1 me-1 mx-1 text-primary'}
            >
              <FaEye />
            </Link>

            {permissions?.print ? (
              <PrintButton
                variant="transparent"
                className="p-1 me-1 mx-1 text-black"
                style={{ boxShadow: 'none' }}
                url={`purchase/purchase-invoices/print/${rowData.row.original.id}`}
                pageTitle={titlePreFix + 'urchase invoice print view'}
              />
            ) : null}

            {permissions?.edit ? (
              <Link
                to={`/${pathPreFix}/purchase-invoice/edit/${rowData.row.original.id}`}
                className={'p-1 me-1 mx-1 text-primary'}
              >
                <FaEdit />
              </Link>
            ) : null}

            {handleDelete && (
              <Button
                variant="transparent"
                className="p-1 me-1 mx-1 text-danger"
                style={{ boxShadow: 'none' }}
                onClick={() => handleDelete(rowData.row.original)}
              >
                <BsFillTrashFill />
              </Button>
            )}
          </div>
        );
      }
    }
  ];

  return (
    <Card className="mb-3 flex-fill">
      <Card.Header className="d-flex flex-row justify-content-between align-items-center border-bottom">
        <TableHeader
          title={Translate(titlePreFix + 'urchase invoices')}
          table
        />
        <CommonSearch />
        <ExportButton
          className="me-1"
          url={`purchase/${
            isFixedAsset ? 'fixed-asset-' : ''
          }purchase-invoices/export`}
          initialMessage={Translate(
            titlePreFix + 'urchase invoice export started'
          )}
          successMessage={Translate(
            titlePreFix + 'urchase invoice export completed'
          )}
          file_type={'pdf'}
          filters={FormattedData(JSON.parse(filterQueryParam))}
        />
        {permissions?.create ? (
          <AddButton to={`/${pathPreFix}/purchase-invoice/add`} />
        ) : (
          ''
        )}
      </Card.Header>
      <Card.Body className="p-0">
        <Filter
          moduleName={filterModuleNamePreFix + 'purchase_invoice'}
          enableDropDown
          dropDownData={paymentStatus}
        />
        {!isLoading && data && data.data ? (
          <AdvanceTableWrapper
            columns={columns}
            data={data?.data ?? []}
            sortable
            pagination
            perPage={data?.meta?.per_page}
          >
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
              view
              entry_params
              to={`/${pathPreFix}/purchase-invoice/`}
              allQueryParams={allQueryParams}
            />
          </AdvanceTableWrapper>
        ) : (
          <div
            className=" d-flex align-item-center justify-content-center"
            style={{ height: '60vh' }}
          >
            <LoadingScreen message="Looking for data" />
          </div>
        )}
      </Card.Body>
      <Card.Footer>
        {!isLoading && data && data.links && (
          <AppPagination
            data={data}
            url={`/${pathPreFix}/purchase-invoice`}
            search={allQueryParams}
          />
        )}
      </Card.Footer>
    </Card>
  );
};

PurchaseInvoiceTableView.propTypes = {
  data: PropTypes.any,
  handleDelete: PropTypes.func,
  isLoading: PropTypes.bool,
  isFixedAsset: PropTypes.bool
};

export default PurchaseInvoiceTableView;
