import { React, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button, Card, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { MdClose } from 'react-icons/md';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import AfterSaveNewButton from './components/AfterSaveNewButton';
import AfterSaveViewButton from './components/AfterSaveViewButton';
import ViewPagePrintButton from 'module/Common/ViewPages/Common/ViewPagePrintButton';
import UnUsedCreditsPopup from 'module/Common/ViewPages/Common/UnUSedCredits/UnUsedCreditsPopup';
import PaymentScreenPopup from 'module/Common/ViewPages/Common/PaymentScreenPopup';

function AfterSaveModal({
  show,
  onHide,
  afterSave,
  data,
  setDataProcessing,
  dataProcessing,
  module
}) {
  let navigate = useNavigate();
  const [customerData, setCustomerData] = useState({});
  const [unUsedCredits, setUnUsedCredits] = useState(false);
  const [payment, setPayment] = useState(false);
  const [person, setPerson] = useState({});
  const Translate = useAxisproTranslate();

  useEffect(() => {
    if (data?.customer_id) {
      axios
        .get(`sales/customers/${data?.customer_id}`)
        .then(res => {
          if (res?.data?.success) {
            setCustomerData(res?.data?.data);
            setPerson(() => ({
              personType: 'CUSTOMER',
              personId: res?.data?.data?.id,
              name: res?.data?.data?.name,
              value: res?.data?.data?.customer_id
            }));
          }
          setDataProcessing(false);
        })
        .catch(error => {
          console.log(error);
        });
    } else if (data?.supplier) {
      setCustomerData(data.supplier);
      setPerson(() => ({
        personType: 'SUPPLIER',
        personId: data.supplier?.id,
        name: data.supplier?.name,
        value: data.supplier?.customer_id
      }));
    }
  }, [data]);

  useEffect(() => {
    if (!show) {
      setCustomerData({});
    }
  }, [show]);

  const viewPageUrl = `${afterSave?.viewButton?.url}${
    module !== 'PURCHASE' ? '/' + data?.id : `?entry=${data?.id}&layout=column`
  }`;
  const handleNavigate = () => {
    navigate(viewPageUrl);
  };

  const handleCloseNavigate = () => {
    navigate(afterSave?.listPageUrl ?? '');
  };

  return (
    <Modal
      show={!dataProcessing && show}
      onHide={onHide}
      size="md"
      centered
      backdrop={'static'}
      className="custom-modal-position"
    >
      <Card>
        <Card.Header className="after-save-modal-header pb-0">
          <Button variant="transparent" onClick={handleCloseNavigate}>
            <MdClose size={20} />
          </Button>
        </Card.Header>
        <Card.Body className="pt-0">
          <h1 className="fs--1">{Translate(afterSave?.mainData) ?? ''}</h1>
          <h1 className="fs-1">{data?.reference ?? ''}</h1>
          <div className="mt-4 text-center">
            {customerData?.unused_credits > 0 ? (
              <>
                <p className="m-1">
                  {Translate(
                    `${module === 'PURCHASE' ? 'Supplier' : 'Customer'} has`
                  )}
                  {'  '}
                  <span className="fs-1 fw-bold text-dark">
                    {customerData?.unused_credits_formatted}
                  </span>{' '}
                  {Translate('advance amount')}
                </p>
                <p
                  className="m-0 text-primary"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setUnUsedCredits(true)}
                  onMouseOver={e =>
                    (e.currentTarget.style.textDecoration = 'underline')
                  }
                  onMouseOut={e =>
                    (e.currentTarget.style.textDecoration = 'none')
                  }
                >
                  {Translate('Click here to allocate!')}
                </p>
              </>
            ) : null}
          </div>
          {customerData?.unused_credits > 0 ? (
            <div className="p-2">
              <h1 className="m-0 fw-bold">{Translate('OR')}</h1>
            </div>
          ) : null}
          <div className="mt-2 text-center">
            <p
              className="m-0 text-primary"
              style={{ cursor: 'pointer' }}
              onClick={() => setPayment(true)}
              onMouseOver={e =>
                (e.currentTarget.style.textDecoration = 'underline')
              }
              onMouseOut={e => (e.currentTarget.style.textDecoration = 'none')}
            >
              {Translate(
                `Click here to ${
                  module === 'PURCHASE' ? 'make' : 'receive'
                } payment!`
              )}
            </p>
          </div>
        </Card.Body>
        <Card.Footer className="d-flex gap-1 align-items-center justify-content-center pt-0">
          <ViewPagePrintButton
            title={'Print'}
            variant="danger"
            className="text-white"
            style={{ boxShadow: 'none' }}
            url={`${afterSave?.printButton?.url}/${data?.id}`}
            pageTitle={`${afterSave?.printButton?.pageTitle}`}
            onHide={onHide}
          />
          <AfterSaveNewButton
            variant="success"
            className="text-white"
            title={afterSave?.newButton?.title}
            onClick={afterSave?.newButton?.onClick}
          />
          <AfterSaveViewButton
            variant="info"
            className="text-white"
            title={afterSave?.viewButton?.title}
            url={viewPageUrl}
          />
        </Card.Footer>
      </Card>

      <UnUsedCreditsPopup
        show={unUsedCredits}
        onHide={() => setUnUsedCredits(false)}
        person={person}
        transType={data?.trans_type}
        transId={data?.voucher_id}
        reference={data?.reference}
        dueAmount={parseFloat(data?.balance)}
        reLoad={handleNavigate}
      />

      <PaymentScreenPopup
        show={payment}
        person={person}
        invoiceData={data}
        onHide={() => setPayment(false)}
        dueAmount={parseFloat(data?.balance)}
        fetchData={handleNavigate}
      />
    </Modal>
  );
}

AfterSaveModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  setDataProcessing: PropTypes.func,
  dataProcessing: PropTypes.bool,
  afterSave: PropTypes.any,
  data: PropTypes.any,
  module: PropTypes.oneOf(['SALES', 'PURCHASE'])
};

export default AfterSaveModal;
