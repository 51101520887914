import { React, useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, Form, Card, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { showToast } from 'module/Common/Toast/toast';
import AppDatePicker from 'components/app-date-picker/AppDatePicker';
import { CancelButton, SaveButton } from 'module/Common/Buttons/CommonButton';
import { FaRegFileAlt } from 'react-icons/fa';
import ErrorAlert from 'module/Common/Error/ErrorAlert';
import SelectWarehouse from 'components/form/SelectWarehouse';
import SelectPaymentTerms from 'components/form/SelectPaymentTerms';
import generateFormDataHelper from 'helpers/generateFormDataHelper';
import setNewFormData from 'helpers/setNewFormData';
import removeRefData from 'helpers/removeRefData';
import { rentalMakeInvoiceFormKeys } from 'helpers/formKeys';
import classNames from 'classnames';
function RentalMakeInvoiceModal({ show, setShow, data, fetchData }) {
  const Translate = useAxisproTranslate();
  const [onSave, setOnSave] = useState(false);
  const [formData, setFormData] = useState(rentalMakeInvoiceFormKeys);
  const [validationErrorMsg, setValidationErrorMsg] = useState({});
  const [shakeModal, setShakeModal] = useState(false);

  const handleSubmit = () => {
    setOnSave(true);
    let newFormData = setNewFormData(removeRefData(formData));
    axios
      .post(`sales/rental-contract-invoice`, newFormData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => {
        showToast(response.data.message, 'success');
        fetchData();
        setFormData({});
        setShow(false);
        setOnSave(false);
      })
      .catch(error => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          setShakeModal(true);
          setTimeout(() => {
            setShakeModal(false);
          }, 500);
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setValidationErrorMsg({ ...validation_error });
        } else {
          showToast(
            Translate(
              'Something went wrong, please refresh the page and try again.'
            ),
            'error'
          );
        }
        setOnSave(false);
      });
  };
  const handleFieldChange = (e, action) => {
    let newFormData = generateFormDataHelper(e, action, formData);
    setFormData(newFormData);
    if (
      action?.name === 'payment_term_id' &&
      action.action === 'select-option'
    ) {
      let today = new Date();
      today.setDate(today.getDate() + e?.days);
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      let newData = `${year}-${month}-${day}`;
      setFormData(prev => ({
        ...prev,
        due_date: newData
      }));
    }
  };
  const handleCancel = () => {
    setFormData(rentalMakeInvoiceFormKeys);
    setValidationErrorMsg({});
    setShow(false);
  };
  useEffect(() => {
    if (data?.id) {
      setFormData(prev => ({
        ...prev,
        contract_id: data?.id,
        customer_id: data?.customer_id,
        sales_type_id: data?.sales_type_id,
        unit_price: data?.amount,
        details: [
          {
            unit_price: data?.amount,
            discount_amount: 0
          }
        ]
      }));
    }
  }, [data]);
  return (
    <Modal
      show={show}
      className={`modal-with-overlay ${classNames({
        'modal-shake': shakeModal
      })}`}
      backdrop="static"
      onHide={handleCancel}
    >
      <Card>
        <Modal.Header
          className="fs--1 text-uppercase text-dark d-flex align-items-center border-bottom"
          closeButton
        >
          <FaRegFileAlt size={20} className="me-2" />
          {Translate('Make Invoice')}
        </Modal.Header>
        <Card.Body>
          <Form className="app-form">
            {Object.keys(validationErrorMsg).length > 0 && (
              <ErrorAlert
                formError={validationErrorMsg}
                setFormError={setValidationErrorMsg}
              />
            )}
            <div
              className={`d-flex flex-wrap border p-3`}
              style={{
                backgroundColor: 'aliceblue'
              }}
            >
              <div className="me-auto">
                <div className="d-flex flex-row gap-2 mb-1">
                  <p className="fw-bold text-dark m-0">
                    {Translate('Customer Name')} :
                  </p>
                  <div className="d-flex flex-row align-items-center gap-2">
                    <p className="m-0 ">{data?.customer_name}</p>
                  </div>
                </div>
                <div className="d-flex flex-row gap-2 mb-1">
                  <p className="fw-bold text-dark m-0">
                    {Translate('Sales Type')} :
                  </p>
                  <div className="d-flex flex-row align-items-center gap-2">
                    <p className="m-0 ">{data?.sales_type}</p>
                  </div>
                </div>
                <div className="d-flex flex-row gap-2 mb-1">
                  <p className="fw-bold text-dark m-0">
                    {Translate('Amount')} :
                  </p>
                  <div className="d-flex flex-row align-items-center gap-2">
                    <p className="m-0 text-success">{data?.amount_formatted}</p>
                  </div>
                </div>
              </div>
            </div>
            <Row className=" mt-2 ">
              <Form.Group md={12} className="mb-3">
                <Form.Label className="require-data">
                  {Translate('Date')}
                </Form.Label>
                <AppDatePicker
                  name={'trans_date'}
                  onChange={handleFieldChange}
                  isInvalid={validationErrorMsg?.trans_date}
                  value={formData?.trans_date}
                  yearPlaceholder="yyyy"
                  monthPlaceholder="mm"
                  dayPlaceholder="dd"
                  showDefaultDate
                />
              </Form.Group>
              <Form.Group md={12} className="mb-3">
                <Form.Label className="require-data">
                  {Translate('Delivery Location')}
                </Form.Label>
                <SelectWarehouse
                  name="warehouse_id"
                  value={formData?.warehouse_id_ref}
                  handleFieldChange={handleFieldChange}
                  error={validationErrorMsg?.warehouse_id}
                />
              </Form.Group>
              <Form.Group md={12} className="mb-2">
                <Form.Label className="require-data">
                  {Translate('Payment Term')}
                </Form.Label>
                <SelectPaymentTerms
                  name="payment_term_id"
                  value={formData?.payment_term_id_ref}
                  handleFieldChange={handleFieldChange}
                  error={validationErrorMsg?.payment_term_id}
                  includeThisKeys={['days']}
                />
              </Form.Group>
              <Form.Group md={12} className="mb-3">
                <Form.Label className="require-data">
                  {Translate('Due Date')}
                </Form.Label>
                <AppDatePicker
                  name={'due_date'}
                  onChange={handleFieldChange}
                  isInvalid={validationErrorMsg?.due_date}
                  value={formData?.due_date}
                  yearPlaceholder="yyyy"
                  monthPlaceholder="mm"
                  dayPlaceholder="dd"
                />
              </Form.Group>
              <Form.Group md={12} sm={12} className="">
                <Form.Label>{Translate('Notes')}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  name="memo"
                  className="w-100"
                  value={formData?.memo}
                  onChange={handleFieldChange}
                />
              </Form.Group>
            </Row>
          </Form>
        </Card.Body>
        <Card.Footer className="d-flex gap-1 pe-3 justify-content-end border-top">
          <CancelButton handleCancel={handleCancel} onSave={onSave} />
          <SaveButton
            buttonTitle={'Make Invoice'}
            createPermission={true}
            editPermission={true}
            handleSubmit={handleSubmit}
            onSave={onSave}
          />
        </Card.Footer>
      </Card>
    </Modal>
  );
}

RentalMakeInvoiceModal.propTypes = {
  data: PropTypes.object,
  show: PropTypes.func,
  setShow: PropTypes.func,
  fetchData: PropTypes.func
};

export default RentalMakeInvoiceModal;
