import { React, useEffect, useState, useContext, useLayoutEffect } from 'react';
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom';
import axios from 'axios';
import { Badge, Button, Card, Col, Form, Row } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';

import { FiChevronsRight } from 'react-icons/fi';
import { AiFillFileAdd } from 'react-icons/ai';
import { FaEdit } from 'react-icons/fa';
import { BiRevision } from 'react-icons/bi';
import { MdOutlineClose } from 'react-icons/md';

import {
  commonEndPoints,
  salesEndPoints,
  settingsEndPoints
} from 'module/Common/Api/endpoints';
import { AuthWizardContext, StoreContext } from 'context/Context';
import { showToast } from 'module/Common/Toast/toast';
import { CancelButton, SaveButton } from 'module/Common/Buttons/CommonButton';
import { removeKitDetails } from 'module/Common/Functions/SalesModuleSupportingFunctions';
import { DateFormatVaildation } from 'module/Common/Functions/DateFormatVaildation';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import { removeEmptyCustomField } from 'module/Common/Functions/CustomFieldFunction';
import { CustomFieldFormValidation } from 'module/Common/FormValidations/common/CustomFieldValidations';
import { fetchCustomerDetails } from '../common/api/fetchCustomerDetails';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import useRemoveParams from 'module/Common/Hooks/useRemoveParams';
import SalesQuotationTable from './SalesQuotationTable/SalesQuotationTable';
import Total from '../../Common/Total';
import DraftData from '../../Common/DraftSupportingFiles/DraftData';
import ErrorAlert from 'module/Common/Error/ErrorAlert';
import generateFormDataHelper from 'helpers/generateFormDataHelper';
import FileController from 'components/file-controller/FileController';
import removeRefData from 'helpers/removeRefData';
import setNewFormData from 'helpers/setNewFormData';
import CustomerInputField from 'module/Common/InputFields/CustomerInputField';
import CostCenterInputField from 'module/Common/InputFields/CostCenterInputField';
import EmailInputField from 'module/Common/InputFields/EmailInputField';
import ContactNumberInputField from 'module/Common/InputFields/ContactNumberInputField';
import DateInputField from 'module/Common/InputFields/Common/DateInputField';
import CustomerRef from 'module/Common/InputFields/Sales/CustomerRef';
import SalesTypeInputField from 'module/Common/InputFields/SalesTypeInputField';
import PaymentTermInputField from 'module/Common/InputFields/PaymentTermInputField';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import FormLayer from 'module/Common/Layer/FormLayer';
import TermsAndConditions from 'module/Common/InputFields/Sales/TermsAndConditions';
import TextEditor from 'module/Common/InputFields/TextEditor/TextEditor';
import SalesmanInputField from 'module/Common/InputFields/SalesmanInputField';
import CustomInputFields from 'module/Common/InputFields/Common/CustomInputFields';
import setEstimationToSalesQuotationData from '../common/functions/setEstimationToSalesQuotationData';
import EstimationReferenceView from 'components/EstimationReferenceView/EstimationReferenceView';
import setEnquiryToSalesQuotationData from '../common/functions/setEnquiryToSalesQuotationData';
import EnquiryReferenceView from 'components/EnquiryReferenceView/EnquiryReferenceView';

const SalesQuotationForm = () => {
  const Translate = useAxisproTranslate();
  let navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const { user } = useContext(AuthWizardContext);
  const { salesQuotationId } = useParams();
  const { store } = useContext(StoreContext);
  const permission = {
    create_sq: getAcodaxPermissionSlug('SQ', 'create'),
    update_sq: getAcodaxPermissionSlug('SQ', 'update')
  };
  const [formData, setFormData] = useState({
    draft_id: '',
    trans_type: 'SQ',
    customer_id: '',
    customer_id_ref: '',
    sales_type_id: '',
    sales_type_id_ref: '',
    trans_date: '',
    expected_delivery_date: '',
    cost_center_id: '',
    cost_center_id_ref: '',
    payment_term_id: '',
    payment_term_id_ref: '',
    memo: '',
    mobile: '',
    email: '',
    details: [],
    terms_and_conditions: '',
    customer_reference: '',
    salesman_id: '',
    salesman_id_ref: '',
    custom_fields: {}
  });
  const [isFromCrm, setIsFromCrm] = useState(false);
  const [sqFromCrm, setSqFromCrm] = useState();
  const [editItemData, setEditItemData] = useState({});
  const [onSave, setOnSave] = useState(false);
  const [formError, setFormError] = useState({});
  const [transDate, setTransDate] = useState();
  const [subTotal, setSubTotal] = useState('');
  const [netDiscount, setNetDiscount] = useState('');
  const [tax, setTax] = useState('');
  const [draft, setDraft] = useState(false);
  const [savedDraft, setSavedDraft] = useState(false);
  const [savedDraftData, setSavedDraftData] = useState({});
  const [draftId, setDraftId] = useState('');
  const [draftBugFix, setDraftBugFix] = useState(false);
  const [resumeDraftData, setResumeDraftData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteDraft, setDeleteDraft] = useState(false);
  const [fetchDraftData, setFetchDraftData] = useState({
    draft_id: '',
    trans_type: 'SQ',
    customer_id: '',
    customer_id_ref: '',
    sales_type_id: '',
    sales_type_id_ref: '',
    trans_date: '',
    cost_center_id: '',
    cost_center_id_ref: '',
    payment_term_id: '',
    payment_term_id_ref: '',
    expected_delivery_date: '',
    memo: '',
    mobile: '',
    email: '',
    details: [],
    terms_and_conditions: '',
    customer_reference: '',
    salesman_id: '',
    salesman_id_ref: '',
    custom_fields: {}
  });
  const [taxType, setTaxType] = useState(false);
  const [dataProcessing, setDataProcessing] = useState(false);
  const [editDisplay, setEditDisplay] = useState(false);
  const [salesType, setSalesType] = useState([]);
  const allQueryParams = Object.fromEntries([...queryParams]);
  const [kitItems, setKitItems] = useState([]);
  const [kitFullDetails, setKitFullDetails] = useState([]);
  const [savingDraft, setSavingDraft] = useState(false);
  const [buttonClick, setButtonClick] = useState(false);
  const [customFields, setCustomFields] = useState([]);
  const [termsFormData, setTermsFormData] = useState({
    terms: '',
    terms_ref: ''
  });
  const estimationId = queryParams.get('estimation_id');
  const enquiryId = queryParams.get('enquiry_id');
  const reviseSq = queryParams.get('revise') ?? false;
  const SalesSettings = user?.branch?.sales_settings;

  //Sales Quotation data fetching for editing
  useEffect(() => {
    setLoading(true);
    if (salesQuotationId || sqFromCrm) {
      setEditDisplay(true);
      axios
        .get(
          `${salesEndPoints.sales_quotation}/${salesQuotationId || sqFromCrm}`
        )
        .then(res => {
          axios
            .get(
              `${settingsEndPoints.sales_type}/${res.data.data.sales_type_id}`
            )
            .then(res => {
              if (res.data.data.tax_included > 0) {
                setTaxType(true);
              } else {
                setTaxType(false);
              }
            })
            .catch(error => {
              console.log(error);
            });
          setSalesType([res.data.data.sales_type_id]);
          setEditItemData(res.data.data);
          setTransDate(res.data.data.trans_date);
          if (res.data.data && res.data.data.details) {
            let editKitDetails = res.data.data.details.filter(
              item => item.is_kit == 1
            );
            setKitFullDetails([...editKitDetails]);
            const allItems = editKitDetails
              .map(obj => obj.kit_items)
              .reduce((acc, arr) => acc.concat(arr), []);
            setKitItems([...allItems]);
          }
          setTimeout(() => setLoading(false), 1000);
        })
        .catch(error => {
          console.log(error);
          setLoading(false);
        });
    } else if (estimationId) {
      // set estimation data
      setEstimationToSalesQuotationData({
        user: user,
        estimationId: estimationId,
        setLoading: setLoading,
        setFormData: setFormData
      });
    } else if (enquiryId) {
      // set enquiry data
      setEnquiryToSalesQuotationData({
        enquiryId: enquiryId,
        setLoading: setLoading,
        setFormData: setFormData
      });
    }
  }, [salesQuotationId, estimationId, enquiryId, sqFromCrm]);

  const handleFieldChange = (e, action) => {
    if (action && action.action === 'text-editor') {
      setFormData(prev => ({
        ...prev,
        [action.name]: action.value
      }));
    } else {
      setFormData(generateFormDataHelper(e, action, formData));
    }
  };

  const handleQuotationTerms = (e, action) => {
    setTermsFormData(generateFormDataHelper(e, action, termsFormData));
  };

  const addQuotationTerms = async termsID => {
    if (termsFormData.terms || termsID) {
      try {
        const { data } = await axios.get(
          `${settingsEndPoints.terms_and_conditions}/${
            termsFormData.terms || termsID
          }`
        );
        const description = data.data;
        setFormData(prev => ({
          ...prev,
          terms_and_conditions: `${
            prev?.terms_and_conditions ? prev?.terms_and_conditions : ''
          }<br/>${description?.description}`
        }));
        setTermsFormData({ terms: '', terms_ref: '' });
        setDraft(!draft);
      } catch (error) {
        console.log(error);
      }
    }
  };

  //Draft fetching for add sales quotation
  const fetchDraft = async () => {
    await axios
      .get(commonEndPoints.draft.find, {
        params: { trans_type: 'SQ' }
      })
      .then(response => {
        setLoading(false);
        if (response.data.data.length !== 0) {
          setSalesType([response.data.data.draft_data.sales_type_id]);
          setSavedDraftData(response.data);
          setSavedDraft(true);
          setFetchDraftData(prev => ({
            ...prev,
            draft_id: response.data.data.id,
            trans_type: response.data.data.trans_type,
            customer_id: response.data.data.draft_data.customer_id,
            customer_id_ref:
              response?.data?.data?.draft_data?.customer_id_ref ?? '',
            terms_and_conditions:
              response.data.data.draft_data.terms_and_conditions,
            expected_delivery_date:
              response.data.data.draft_data.expected_delivery_date,
            cost_center_id: response.data.data.draft_data.cost_center_id,
            cost_center_id_ref:
              response?.data?.data?.draft_data?.cost_center_id_ref ?? '',
            memo: response.data.data.draft_data.memo
              ? response.data.data.draft_data.memo
              : '',
            email: response.data.data.draft_data.email,
            mobile: response.data.data.draft_data.mobile
              ? response.data.data.draft_data.mobile
              : '',
            trans_date: response.data.data.draft_data.trans_date,
            sales_type_id: response.data.data.draft_data.sales_type_id,
            sales_type_id_ref:
              response?.data?.data?.draft_data?.sales_type_id_ref ?? '',
            salesman_id: response.data.data.draft_data.salesman_id,
            salesman_id_ref:
              response?.data?.data?.draft_data?.salesman_id_ref ?? '',
            payment_term_id: response.data.data.draft_data.payment_term_id,
            payment_term_id_ref:
              response?.data?.data?.draft_data?.payment_term_id_ref ?? '',
            details:
              response.data.data.draft_data.details === ''
                ? []
                : response.data.data.draft_data.details,
            customer_reference: response.data.data.draft_data.customer_reference
              ? response.data.data.draft_data.customer_reference
              : '',
            custom_fields: response?.data?.data?.draft_data?.custom_fields
              ? response?.data?.data?.draft_data?.custom_fields
              : {}
          }));
          setDraftId(response.data.data.id);
          if (response.data.data && response.data.data.draft_data.details) {
            let editKitDetails = response.data.data.draft_data.details.filter(
              item => item.is_kit == 1
            );
            setKitFullDetails([...editKitDetails]);
            const allItems = editKitDetails
              .map(obj => obj.kit_items)
              .reduce((acc, arr) => acc.concat(arr), []);
            setKitItems([...allItems]);
          }
        } else {
          setSavedDraft(false);
          setDraftId(0);
        }
      })
      .catch(error => {
        console.log(error);
        setDraftId(0);
      });
  };

  //Draft fetching for edit sales quotation
  const fetchEditDraft = async () => {
    if (salesQuotationId && !reviseSq) {
      await axios
        .get(commonEndPoints.draft.find, {
          params: { trans_type: 'SQ', trans_no: salesQuotationId }
        })
        .then(response => {
          if (response?.data?.data?.draft_data) {
            setSalesType([response.data.data.draft_data.sales_type_id]);
            setSavedDraftData(response.data);
            setDataProcessing(false);
            setSavedDraft(true);
            setFetchDraftData(prev => ({
              ...prev,
              draft_id: response.data.data.id,
              trans_type: response.data.data.trans_type,
              trans_no: response.data.data.trans_no,
              expected_delivery_date:
                response.data.data.draft_data.expected_delivery_date,
              terms_and_conditions:
                response.data.data.draft_data.terms_and_conditions,
              customer_id: response.data.data.draft_data.customer_id,
              customer_id_ref:
                response?.data?.data?.draft_data?.customer_id_ref ?? '',
              cost_center_id: response.data.data.draft_data.cost_center_id,
              cost_center_id_ref:
                response?.data?.data?.draft_data?.cost_center_id_ref ?? '',
              memo: response.data.data.draft_data.memo
                ? response.data.data.draft_data.memo
                : '',
              email: response.data.data.draft_data.email,
              mobile: response.data.data.draft_data.mobile,
              trans_date: response.data.data.draft_data.trans_date,
              sales_type_id: response.data.data.draft_data.sales_type_id,
              sales_type_id_ref:
                response?.data?.data?.draft_data?.sales_type_id_ref ?? '',
              salesman_id: response.data.data.draft_data.salesman_id,
              salesman_id_ref:
                response?.data?.data?.draft_data?.salesman_id_ref ?? '',
              payment_term_id: response.data.data.draft_data.payment_term_id,
              payment_term_id_ref:
                response?.data?.data?.draft_data?.payment_term_id_ref ?? '',
              details:
                response.data.data.draft_data.details === ''
                  ? []
                  : response.data.data.draft_data.details,
              customer_reference: response.data.data.draft_data
                .customer_reference
                ? response.data.data.draft_data.customer_reference
                : '',
              custom_fields: response?.data?.data?.draft_data?.custom_fields
                ? response?.data?.data?.draft_data?.custom_fields
                : {}
            }));
            setDraftId(response.data.data.id);
            if (response.data.data && response.data.data.draft_data.details) {
              let editKitDetails = response.data.data.draft_data.details.filter(
                item => item.is_kit == 1
              );
              setKitFullDetails([...editKitDetails]);
              const allItems = editKitDetails
                .map(obj => obj.kit_items)
                .reduce((acc, arr) => acc.concat(arr), []);
              setKitItems([...allItems]);
            }
          } else {
            setSavedDraft(false);
            setDraftId(0);
          }
        })
        .catch(error => {
          console.log(error);
          setDraftId(0);
        });
    }
  };

  //Fetched draft data populating to the form
  useEffect(() => {
    if (resumeDraftData === true) {
      setSalesType([fetchDraftData.sales_type_id]);
      axios
        .get(`${settingsEndPoints.sales_type}/${fetchDraftData.sales_type_id}`)
        .then(response => {
          if (response?.data?.data?.tax_included > 0) {
            setTaxType(true);
          } else {
            setTaxType(false);
          }
        })
        .catch(error => {
          console.log(error);
        });
      const {
        draft_id,
        trans_type,
        customer_id,
        customer_id_ref,
        cost_center_id,
        cost_center_id_ref,
        payment_term_id,
        payment_term_id_ref,
        memo,
        email,
        mobile,
        trans_date,
        sales_type_id,
        sales_type_id_ref,
        expected_delivery_date,
        terms_and_conditions,
        customer_reference,
        salesman_id,
        salesman_id_ref,
        details = [],
        custom_fields = {}
      } = fetchDraftData;
      setFormData({
        draft_id: draft_id,
        trans_type: trans_type,
        trans_no: salesQuotationId,
        customer_id: customer_id,
        customer_id_ref: customer_id_ref,
        cost_center_id: cost_center_id,
        cost_center_id_ref: cost_center_id_ref,
        payment_term_id: payment_term_id,
        payment_term_id_ref: payment_term_id_ref,
        memo: memo,
        email: email,
        mobile: mobile,
        trans_date: trans_date,
        sales_type_id: sales_type_id,
        sales_type_id_ref: sales_type_id_ref,
        expected_delivery_date: expected_delivery_date,
        details: details,
        terms_and_conditions: terms_and_conditions,
        customer_reference: customer_reference,
        salesman_id: salesman_id,
        salesman_id_ref: salesman_id_ref,
        custom_fields:
          Object.keys(custom_fields)?.length > 0 ? custom_fields : {},
        is_revision: reviseSq ? 1 : null
      });
    }
  }, [resumeDraftData]);

  //Draft saving function
  const saveDraft = () => {
    let formDataWithoutRefkeys = {
      ...removeRefData({ ...formData })
    };
    let result = Object.values(formDataWithoutRefkeys).filter(
      value => value !== null && value !== undefined
    ); //values from the formData seperated and stored to result
    let resultData = result.filter(item => {
      //result filtered and stored to resultData
      return (
        item !== '' &&
        item !== 'SQ' &&
        item !== undefined &&
        /\S/.test(item) &&
        Object.keys(item).length !== 0 &&
        item !== store?.currentDate && //When component rendering current date is set to transaction date so draft is creating need to avoid that
        item !== user?.branch.sales_settings?.sales_type_id &&
        item !== user?.branch.sales_settings?.sales_type_name
      );
    });
    if (resultData.length > 0) {
      if (resultData.length === 1) {
        if (resultData[0] === formData.draft_id) {
          axios
            .delete(`${commonEndPoints.draft.delete}/${formData.draft_id}`)
            .then(res => {
              console.log(res);
              setFormData(prev => ({
                ...prev,
                draft_id: ''
              }));
              setKitItems([]);
              setKitFullDetails([]);
            })
            .catch(error => {
              console.log(error);
            });
        } else {
          setSavingDraft(true);
          axios
            .post(commonEndPoints.draft.save, formData)
            .then(response => {
              setSavingDraft(false);
              setDraftId(response.data.data.draft_id);
              setFormData(prev => ({
                ...prev,
                draft_id: response.data.data.draft_id
              }));
            })
            .catch(error => console.log(error));
        }
      } else {
        setSavingDraft(true);
        axios
          .post(commonEndPoints.draft.save, formData)
          .then(response => {
            setSavingDraft(false);
            if (formData.draft_id === '' || formData.draft_id === undefined) {
              setFormData(prev => ({
                ...prev,
                draft_id: response.data.data.draft_id
              }));
            }
            setDraftId(response.data.data.draft_id);
          })
          .catch(error => console.log(error));
      }
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (draftId !== 0 && !reviseSq && !estimationId && !isFromCrm) {
      saveDraft();
    } else if (draftId === 0 && !reviseSq && !estimationId && !isFromCrm) {
      saveDraft();
    }
  }, [draft]);

  useLayoutEffect(() => {
    if (salesQuotationId && !reviseSq && !isFromCrm) {
      fetchEditDraft(); //Edit Draft fetched here
    } else if (!estimationId && !reviseSq && !isFromCrm) {
      fetchDraft(); //Add draft fetched here
    }
  }, [salesQuotationId]);

  const handleFiles = files => {
    handleFieldChange({ target: { name: 'files', value: files } });
  };
  let newStockKitItems = [...formData.details, ...kitItems];

  let newDetails = removeKitDetails(newStockKitItems);

  let formDataWithoutRefkeys = {
    ...removeRefData({ ...formData }, 'object', {
      removeValue: [undefined, null]
    }),
    details: removeRefData(newDetails, 'array', {
      removeValue: ['null', null, undefined, true, false, 'true', 'false']
    })
  };

  //If custom field is empty remove that object other wise showing 500 from background
  let formDataWithoutEmptyCustomField = removeEmptyCustomField(
    formDataWithoutRefkeys
  );

  let newFormData = setNewFormData(formDataWithoutEmptyCustomField);

  function sendMessageToParent() {
    window.parent.postMessage({ type: 'SQ_ADDED' }, '*');
  }

  //Form Submission
  const handleSubmit = e => {
    e.preventDefault();
    if (
      CustomFieldFormValidation(
        customFields,
        formData.custom_fields,
        setFormError
      )
    ) {
      if (formData.trans_date && !DateFormatVaildation(formData.trans_date)) {
        showToast(
          'You have selected an invalid date. Please select a valid date',
          'error'
        );
      } else {
        setFormError({});
        setOnSave(true);

        if (salesQuotationId || sqFromCrm) {
          axios({
            method: 'put',
            url: `${salesEndPoints.sales_quotation}/${
              salesQuotationId || sqFromCrm
            }`,
            data: formDataWithoutRefkeys
          })
            .then(response => {
              if (response.data.success === true) {
                setSalesType([]);
                showToast(response.data.message, 'success');
                if (isFromCrm) {
                  sendMessageToParent();
                } else {
                  navigate(
                    `/sales/sales-quotation/info/${salesQuotationId}?${createSearchParams(
                      useRemoveParams(allQueryParams, ['revise'])
                    )}`,
                    {
                      replace: true
                    }
                  );
                }
              } else {
                showToast(
                  Translate(
                    'Something went wrong, please refresh the page and try again'
                  ),
                  'error'
                );
              }
              setOnSave(false);
            })
            .catch(error => {
              if (error.response.data && error.response.data.message) {
                const validation_error =
                  error.response.data &&
                  error.response.data.data &&
                  error.response.data.data.errors
                    ? error.response.data.data.errors
                    : null;
                validation_error && setFormError({ ...validation_error });
              } else {
                showToast(
                  Translate(
                    'Something went wrong, please refresh the page and try again'
                  ),
                  'error'
                );
              }
              setOnSave(false);
            });
        } else {
          axios({
            method: 'post',
            url: `${salesEndPoints.sales_quotation}/`,
            data: newFormData,
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
            .then(response => {
              if (response.data.success === true) {
                setSalesType([]);
                if (isFromCrm) {
                  sendMessageToParent();
                } else {
                  navigate(
                    `/sales/sales-quotation/info/${
                      response.data.data.id
                    }?${createSearchParams(
                      useRemoveParams(allQueryParams, ['estimation_id'])
                    )}`,
                    {
                      replace: true
                    }
                  );
                }
              } else {
                showToast(
                  Translate(
                    'Something went wrong, please refresh the page and try again'
                  ),
                  'error'
                );
              }
              setOnSave(false);
            })
            .catch(error => {
              if (error.response.data && error.response.data.message) {
                const validation_error =
                  error.response.data &&
                  error.response.data.data &&
                  error.response.data.data.errors
                    ? error.response.data.data.errors
                    : null;

                validation_error && setFormError({ ...validation_error });
              } else {
                showToast(
                  Translate(
                    'Something went wrong, please refresh the page and try again'
                  ),
                  'error'
                );
              }
              setOnSave(false);
            });
        }
      }
    }
  };

  //Data populating in form when clicking edit sales quotation which is depends on draft
  //If draft resumed no need to populate this data other wise we need to populate data when we clicking discard draft
  useEffect(() => {
    if (
      (salesQuotationId &&
        draftId === 0 &&
        !draftBugFix &&
        Object.keys(editItemData).length > 0) ||
      (salesQuotationId && reviseSq) ||
      sqFromCrm
    ) {
      // setSalesType([editItemData.sales_type_id]);
      // console.log('third');
      // axios
      //   .get(`settings/sales-types/${editItemData.sales_type_id}`)
      //   .then(response => {
      //     if (response.data.data.tax_included > 0) {
      //       setTaxType(true);
      //     } else {
      //       setTaxType(false);
      //     }
      //   })
      //   .catch(error => {
      //     console.log(error);
      //   });
      setDraftBugFix(true);
      const {
        draft_id,
        customer_id,
        id,
        customer_name,
        cost_center_id,
        cost_center_name,
        payment_term_id,
        payment_term_name,
        memo,
        email,
        mobile,
        trans_date,
        sales_type_id,
        sales_type_name,
        expected_delivery_date,
        details = [],
        terms_and_conditions,
        customer_reference,
        salesman_id,
        salesman_name,
        custom_field_values = []
      } = editItemData;
      setFormData({
        draft_id: draft_id,
        trans_type: 'SQ',
        trans_no: id,
        terms_and_conditions: terms_and_conditions,
        customer_id: customer_id,
        customer_id_ref: customer_name,
        cost_center_id: cost_center_id,
        cost_center_id_ref: cost_center_id
          ? { label: cost_center_name, value: cost_center_id }
          : '',
        payment_term_id: payment_term_id,
        payment_term_id_ref: payment_term_id
          ? {
              label: payment_term_name,
              value: payment_term_id
            }
          : '',
        memo: memo,
        email: email,
        mobile: mobile,
        trans_date: trans_date,
        sales_type_id: sales_type_id,
        sales_type_id_ref: sales_type_id
          ? {
              label: sales_type_name,
              value: sales_type_id
            }
          : '',
        expected_delivery_date: expected_delivery_date,
        details: details,
        customer_reference: customer_reference,
        salesman_id: salesman_id,
        salesman_id_ref: salesman_id
          ? { label: salesman_name, value: salesman_id }
          : '',
        custom_fields:
          custom_field_values?.length > 0
            ? Object.fromEntries(
                custom_field_values.map(({ custom_field_id, value }) => [
                  custom_field_id,
                  value
                ])
              )
            : {},
        ...(editItemData?.estimation_id
          ? {
              estimation_id: editItemData?.estimation_id,
              enquiry_id_ref: editItemData?.enquiry_id,
              enquiry_reference_ref: editItemData?.enquiry?.reference,
              estimate_reference_ref: editItemData?.estimation?.reference
            }
          : null),
        is_revision: reviseSq ? 1 : null
      });
    } else if (
      (salesQuotationId || sqFromCrm) &&
      draftId !== 0 &&
      deleteDraft === true &&
      !draftBugFix &&
      Object.keys(editItemData).length > 0
    ) {
      setDraftBugFix(true);
      setSalesType([editItemData.sales_type_id]);
      axios
        .get(`${settingsEndPoints.sales_type}/${editItemData.sales_type_id}`)
        .then(response => {
          if (response.data.data.tax_included > 0) {
            setTaxType(true);
          } else {
            setTaxType(false);
          }
        })
        .catch(error => {
          console.log(error);
        });
      const {
        draft_id,
        customer_id,
        id,
        customer_name,
        cost_center_id,
        cost_center_name,
        payment_term_id,
        payment_term_name,
        memo,
        email,
        mobile,
        trans_date,
        sales_type_id,
        sales_type_name,
        expected_delivery_date,
        terms_and_conditions,
        details = [],
        customer_reference,
        salesman_name,
        salesman_id,
        custom_field_values = []
      } = editItemData;
      setFormData({
        draft_id: draft_id,
        trans_type: 'SQ',
        trans_no: id,
        customer_id: customer_id,
        customer_id_ref: customer_name,
        cost_center_id: cost_center_id,
        cost_center_id_ref: cost_center_id
          ? { label: cost_center_name, value: cost_center_id }
          : '',
        payment_term_id: payment_term_id,
        payment_term_id_ref: payment_term_id
          ? {
              label: payment_term_name,
              value: payment_term_id
            }
          : '',
        memo: memo,
        email: email,
        mobile: mobile,
        trans_date: trans_date,
        expected_delivery_date: expected_delivery_date,
        sales_type_id_ref: sales_type_id
          ? {
              label: sales_type_name,
              value: sales_type_id
            }
          : '',
        details: details,
        terms_and_conditions: terms_and_conditions ?? '',
        customer_reference: customer_reference,
        salesman_id: salesman_id,
        salesman_id_ref: salesman_id
          ? { label: salesman_name, value: salesman_id }
          : '',
        custom_fields:
          custom_field_values?.length > 0
            ? Object.fromEntries(
                custom_field_values.map(({ custom_field_id, value }) => [
                  custom_field_id,
                  value
                ])
              )
            : {},
        ...(editItemData?.estimation_id
          ? {
              estimation_id: editItemData?.estimation_id,
              enquiry_id_ref: editItemData?.enquiry_id,
              enquiry_reference_ref: editItemData?.enquiry?.reference,
              estimate_reference_ref: editItemData?.estimation?.reference
            }
          : null),
        is_revision: reviseSq ? 1 : null
      });
    }
  }, [deleteDraft, editItemData, draftId]);

  //To select customer
  const handleCustomer = async (valueObject, action) => {
    let customerId =
      valueObject && valueObject.value ? valueObject.value : null;
    if (customerId && action.action === 'select-option') {
      setDataProcessing(true);
      let dataToBePopulate = {
        payment_term: true,
        sales_type: true,
        salesman: true,
        email: true,
        mobile: true,
        details: true
      };
      try {
        await fetchCustomerDetails(
          customerId,
          setFormData,
          valueObject,
          formData,
          dataToBePopulate,
          setSalesType
        );
        setKitFullDetails([]);
        setDraft(!draft);
        setKitItems([]);
        setDataProcessing(false);
      } catch (error) {
        if (error) {
          setDataProcessing(false);
        }
      }
    } else {
      setFormData(prev => ({
        ...prev,
        customer_id: '',
        customer_id_ref: '',
        salesman_id: '',
        salesman_id_ref: '',
        payment_term_id: SalesSettings?.payment_term_id ?? '',
        payment_term_id_ref: SalesSettings?.payment_term_id
          ? {
              label: SalesSettings?.payment_term_name,
              value: SalesSettings?.payment_term_id
            }
          : '',
        sales_type_id: SalesSettings?.sales_type_id ?? '',
        sales_type_id_ref: SalesSettings?.sales_type_id
          ? {
              label: SalesSettings?.sales_type_name ?? '',
              value: SalesSettings?.sales_type_id ?? ''
            }
          : '',
        email: '',
        mobile: '',
        details: []
      }));
      setKitFullDetails([]);
      setDraft(!draft);
      setKitItems([]);
    }
    setFormError({});
  };

  //To select cost center
  const handleCostCenter = (valueObject, action) => {
    setDraft(!draft);
    let costCenterId =
      valueObject && valueObject.value ? valueObject.value : null;
    if (costCenterId && action.action === 'select-option') {
      setFormData(prev => ({
        ...prev,
        cost_center_id: costCenterId,
        cost_center_id_ref: valueObject
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        cost_center_id: '',
        cost_center_id_ref: ''
      }));
    }
    setFormError({});
  };

  //To select sales type
  const handleSalesType = (valueObject, action) => {
    setDraft(!draft);
    if (valueObject && valueObject.taxType > 0) {
      setTaxType(true);
    } else {
      setTaxType(false);
    }
    let salesTypeId =
      valueObject && valueObject.value ? valueObject.value : null;
    if (salesTypeId && action.action === 'select-option') {
      setSalesType([salesTypeId]);
      setFormData(prev => ({
        ...prev,
        sales_type_id: salesTypeId,
        sales_type_id_ref: valueObject
      }));
    } else {
      setSalesType([]);
      setFormData(prev => ({
        ...prev,
        sales_type_id: '',
        sales_type_id_ref: '',
        details: []
      }));
    }
    setFormError({});
  };

  //To select payment terms
  const handlePaymentTerms = (valueObject, action) => {
    setDraft(!draft);
    let paymentTermsId =
      valueObject && valueObject.value ? valueObject.value : null;
    if (paymentTermsId && action.action === 'select-option') {
      setFormData(prev => ({
        ...prev,
        payment_term_id: paymentTermsId,
        payment_term_id_ref: valueObject
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        payment_term_id: '',
        payment_term_id_ref: ''
      }));
    }
    setFormError({});
  };

  const handleSalesman = (valueObject, action) => {
    setDraft(!draft);
    let salesmanId =
      valueObject && valueObject.value ? valueObject.value : null;
    if (salesmanId && action.action === 'select-option') {
      setFormData(prev => ({
        ...prev,
        salesman_id: salesmanId,
        salesman_id_ref: valueObject
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        salesman_id: '',
        salesman_id_ref: ''
      }));
    }
    setFormError({});
  };

  const handleCancel = () => {
    if (isFromCrm) {
      window.parent.postMessage({ type: 'SQ_ABORT' }, '*');
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (salesQuotationId) return;
    if (SalesSettings?.sales_type_id) {
      setSalesType([SalesSettings?.sales_type_id]);
      setFormData(prev => ({
        ...prev,
        sales_type_id: SalesSettings?.sales_type_id ?? '',
        sales_type_id_ref: SalesSettings?.sales_type_id
          ? {
              label: SalesSettings?.sales_type_name ?? '',
              value: SalesSettings?.sales_type_id ?? ''
            }
          : ''
      }));
    }
    if (SalesSettings?.payment_term_id) {
      setFormData(prev => ({
        ...prev,
        payment_term_id: SalesSettings?.payment_term_id ?? '',
        payment_term_id_ref: {
          label: SalesSettings?.payment_term_name ?? '',
          value: SalesSettings?.payment_term_id ?? ''
        }
      }));
    }
    if (deleteDraft) {
      setKitItems([]);
      setKitFullDetails([]);
    }
  }, [user, deleteDraft]);

  const handleInputBoxFocus = () => {
    setButtonClick(true);
  };

  const handleInputBoxOutofFocus = () => {
    setDraft(!draft);
    setTimeout(() => {
      setButtonClick(false);
    }, 1000);
  };

  useEffect(() => {
    setDataProcessing(true);
    axios
      .get(`${settingsEndPoints.custom_field.find}/sq-form`)
      .then(res => {
        if (res.data.success) {
          if (res?.data?.data?.length > 0) {
            // This sorting if for longText custom field should be last to maintain form style
            const arrangedCustomField = res.data.data.sort((a, b) => {
              if (a?.column_type === 'longText') {
                return 1;
              } else if (b?.column_type === 'longText') {
                return -1;
              }
              return 0;
            });
            setCustomFields(arrangedCustomField);
          }
          setDataProcessing(false);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  useEffect(async () => {
    if (formData?.customer_id && estimationId) {
      let dataToBePopulate = {
        payment_term: true,
        salesman: true,
        email: true,
        mobile: true
      };
      try {
        await fetchCustomerDetails(
          formData?.customer_id,
          setFormData,
          formData?.customer_id_ref,
          formData,
          dataToBePopulate,
          setSalesType
        );
      } catch (error) {
        if (error) {
          setDataProcessing(false);
        }
      }
    }
  }, [formData?.customer_id && estimationId]);

  useEffect(() => {
    if (SalesSettings?.default_terms_and_conditions_id) {
      addQuotationTerms(SalesSettings?.default_terms_and_conditions_id);
    }
  }, [SalesSettings?.default_terms_and_conditions_id]);

  // This code is used to access sales quotation form from CRM
  useEffect(async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const data = urlParams.get('data');
    if (data) {
      try {
        setIsFromCrm(true);
        const parsedData = JSON.parse(decodeURIComponent(data));
        if (parsedData?.editId !== '') {
          setSqFromCrm(parsedData?.editId);
        } else {
          setFormData(prev => ({
            ...prev,
            customer_id: parsedData?.customer_id,
            customer_id_ref: {
              label: parsedData?.customer_name,
              value: parsedData?.customer_id
            },
            opportunity_id: parsedData?.opportunity_id,
            is_from_crm: parsedData?.is_from_crm ? 1 : 0
          }));
          if (parsedData?.customer_id) {
            let dataToBePopulate = {
              payment_term: true,
              salesman: true,
              email: true,
              mobile: true
            };
            try {
              await fetchCustomerDetails(
                parsedData?.customer_id,
                setFormData,
                {
                  label: parsedData?.customer_name,
                  value: parsedData?.customer_id
                },
                formData,
                dataToBePopulate,
                setSalesType
              );
            } catch (error) {
              if (error) {
                setDataProcessing(false);
              }
            }
          }
        }
      } catch (error) {
        console.error('Failed to parse data from crm');
      }
    }
  }, []);

  return (
    <>
      <Card className="flex-fill mb-3 form-layer-container">
        {!loading ? (
          <Form>
            {dataProcessing ? <FormLayer /> : ''}
            <Card.Header className="d-flex align-items-center justify-content-between border-bottom">
              <h5 className="form-heading">
                {salesQuotationId || sqFromCrm ? (
                  reviseSq ? (
                    <BiRevision size={25} className="text-dark me-1" />
                  ) : (
                    <FaEdit size={25} className="text-dark me-1" />
                  )
                ) : (
                  <AiFillFileAdd size={25} className="text-dark me-1" />
                )}
                {salesQuotationId || sqFromCrm ? (
                  <>
                    {Translate(
                      `${reviseSq ? 'Revise' : 'Edit'} Sales Quotation`
                    )}{' '}
                    -{' '}
                    <Badge className="ms-2 bg-warning">
                      {editItemData.reference}
                    </Badge>
                  </>
                ) : estimationId ? (
                  <>
                    {Translate('Estimation')}
                    <FiChevronsRight color="red" className="mx-2" />{' '}
                    {Translate('Sales Quotation')}
                  </>
                ) : enquiryId ? (
                  <>
                    {Translate('Enquiry')}
                    <FiChevronsRight color="red" className="mx-2" />{' '}
                    {Translate('Sales Quotation')}
                  </>
                ) : (
                  Translate('Add Sales Quotation')
                )}
              </h5>
              {salesQuotationId && <Badge></Badge>}
              <div className="d-flex gap-1">
                {formData?.enquiry_id_ref ? (
                  <EnquiryReferenceView
                    variant="info"
                    targetId={formData?.enquiry_id_ref}
                    reference={formData?.enquiry_reference_ref}
                  />
                ) : null}

                {formData?.estimation_id ? (
                  <EstimationReferenceView
                    targetId={formData.estimation_id}
                    reference={formData?.estimate_reference_ref}
                  />
                ) : null}
                {isFromCrm && (
                  <Button
                    variant="danger"
                    size="sm"
                    title="Close"
                    onClick={handleCancel}
                    style={{ boxShadow: 'none', outline: 'none' }}
                  >
                    <MdOutlineClose />
                  </Button>
                )}
              </div>
            </Card.Header>
            <SimpleBar
              className={
                isFromCrm
                  ? 'simplebar-style-for-form-in-crm '
                  : 'simplebar-style'
              }
            >
              <Card.Body className="form-wrapper pt-2">
                {!Object.keys(formError).length == 0 ? (
                  <ErrorAlert
                    setFormError={setFormError}
                    formError={formError}
                  />
                ) : (
                  ''
                )}
                <Row>
                  <CustomerInputField
                    formData={formData}
                    handleCustomer={handleCustomer}
                    formError={formError}
                    editDisable={editDisplay || isFromCrm}
                    setFormData={setFormData}
                    id={salesQuotationId || sqFromCrm}
                  />
                </Row>
                <Row>
                  <DateInputField
                    title="Date"
                    name="trans_date"
                    value={formData.trans_date}
                    error={formError.trans_date}
                    formError={formError}
                    handleFieldChange={handleFieldChange}
                    enableDraft
                    draft={draft}
                    setDraft={setDraft}
                    showDefaultDate={
                      salesQuotationId || sqFromCrm ? false : true
                    }
                    required
                  />
                  <PaymentTermInputField
                    formData={formData}
                    handlePaymentTerms={handlePaymentTerms}
                    formError={formError}
                    setFormData={setFormData}
                  />
                  <SalesmanInputField
                    formData={formData}
                    handleSalesman={handleSalesman}
                    formError={formError}
                    setFormData={setFormData}
                  />
                  <SalesTypeInputField
                    formData={formData}
                    handleSalesType={handleSalesType}
                    formError={formError}
                    setFormData={setFormData}
                    setSalesType={setSalesType}
                    title="Sales Type"
                  />
                  <DateInputField
                    title="Expected Delivery Date"
                    name="expected_delivery_date"
                    value={formData.expected_delivery_date}
                    error={formError.expected_delivery_date}
                    formError={formError}
                    handleFieldChange={handleFieldChange}
                    enableDraft
                    draft={draft}
                    setDraft={setDraft}
                  />
                  <EmailInputField
                    formData={formData}
                    formError={formError}
                    handleFieldChange={handleFieldChange}
                    handleInputBoxOutofFocus={handleInputBoxOutofFocus}
                    handleInputBoxFocus={handleInputBoxFocus}
                    enableDraft
                  />
                  <CostCenterInputField
                    formData={formData}
                    handleCostCenter={handleCostCenter}
                    formError={formError}
                    setFormData={setFormData}
                  />
                  <CustomerRef
                    formData={formData}
                    formError={formError}
                    handleFieldChange={handleFieldChange}
                    handleInputBoxOutofFocus={handleInputBoxOutofFocus}
                    handleInputBoxFocus={handleInputBoxFocus}
                    enableDraft
                    readOnly={!!estimationId}
                  />
                  <ContactNumberInputField
                    formData={formData}
                    formError={formError}
                    handleFieldChange={handleFieldChange}
                    handleInputBoxOutofFocus={handleInputBoxOutofFocus}
                    handleInputBoxFocus={handleInputBoxFocus}
                    enableDraft
                  />
                  {!dataProcessing &&
                    customFields &&
                    customFields.length > 0 &&
                    customFields.map((item, key) => (
                      <Form.Group
                        key={key}
                        as={Col}
                        xxl={4}
                        xl={6}
                        md={6}
                        sm={12}
                        className="mb-2"
                      >
                        <CustomInputFields
                          item={item}
                          customFields={formData.custom_fields}
                          formError={formError}
                          enableDraft
                          handleInputBoxOutofFocus={handleInputBoxOutofFocus}
                          handleInputBoxFocus={handleInputBoxFocus}
                        />
                      </Form.Group>
                    ))}
                </Row>
                <SalesQuotationTable
                  entries={formData.details}
                  setFormData={setFormData}
                  formError={formError}
                  setSubTotal={setSubTotal}
                  setNetDiscount={setNetDiscount}
                  setTax={setTax}
                  salesQuotationId={salesQuotationId || sqFromCrm}
                  draft={draft}
                  setDraft={setDraft}
                  resumeDraftData={resumeDraftData}
                  salesType={salesType}
                  setFormError={setFormError}
                  kitItems={kitItems}
                  setKitItems={setKitItems}
                  kitFullDetails={kitFullDetails}
                  setKitFullDetails={setKitFullDetails}
                  formData={formData}
                  transDate={transDate}
                  setUpdateEnable={true}
                  setDataProcessing={setDataProcessing}
                  taxType={taxType}
                  convertType={
                    formData?.estimation_id ? 'estimation' : undefined
                  }
                />
                <Row className="d-flex align-items-end">
                  <Col xs={6}>
                    {salesQuotationId || sqFromCrm ? (
                      ''
                    ) : (
                      <FileController
                        limit={10}
                        limitFeature={true}
                        limitFileSizeInMB={5}
                        limitFileType={[
                          'jpg',
                          'png',
                          'jpeg',
                          'gif',
                          'pdf',
                          'excel',
                          'xlsx',
                          'docx',
                          'doc'
                        ]}
                        onChange={handleFiles}
                        className="my-3"
                        note={'You can upload a maximum of 10 files, 5MB each'}
                      />
                    )}
                    <TermsAndConditions
                      name={'terms'}
                      value={termsFormData.terms_ref}
                      handleFieldChange={handleQuotationTerms}
                      addQuotationTerms={addQuotationTerms}
                    />
                  </Col>
                  <Col xs={6}>
                    <Total
                      subTotal={!isNaN(parseFloat(subTotal)) ? subTotal : 0}
                      netDiscount={
                        !isNaN(parseFloat(netDiscount)) ? netDiscount : 0
                      }
                      tax={!isNaN(parseFloat(tax)) ? tax : 0}
                      taxType={taxType}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} className="pt-4">
                    <Form.Group>
                      <TextEditor
                        name="terms_and_conditions"
                        handleFieldChange={handleFieldChange}
                        placeholder=""
                        value={formData.terms_and_conditions}
                        HideMedia={true}
                        Height="140px"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.memoInput"
                    >
                      <Form.Label>{Translate('Notes')}</Form.Label>
                      <TextEditor
                        name="memo"
                        handleFieldChange={handleFieldChange}
                        value={formData.memo}
                        HideMedia={true}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </SimpleBar>
            <Card.Footer className="footer border-top">
              <SaveButton
                onSave={onSave}
                handleSubmit={handleSubmit}
                id={salesQuotationId || sqFromCrm}
                saveDraft={!buttonClick ? savingDraft : false}
                createPermission={permission?.create_sq}
                editPermission={permission?.update_sq}
                revise={reviseSq}
              />
              <CancelButton
                onSave={onSave}
                handleCancel={handleCancel}
                disable={savingDraft}
              />
            </Card.Footer>
          </Form>
        ) : (
          <Card.Body
            style={{ height: isFromCrm ? '100vh' : '75vh' }}
            className="d-flex align-items-center justify-content-center"
          >
            <LoadingScreen message={Translate('Looking for details')} />
          </Card.Body>
        )}
      </Card>

      <DraftData
        show={savedDraft}
        onHide={() => setSavedDraft(false)}
        savedDraftData={savedDraftData}
        setResumeDraftData={setResumeDraftData}
        setDeleteDraft={setDeleteDraft}
        setDraftId={setDraftId}
        setSalesType={setSalesType}
        sales
      />
    </>
  );
};

export default SalesQuotationForm;
