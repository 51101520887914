import React from 'react';
import { Badge, ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { createSearchParams, Link, useSearchParams } from 'react-router-dom';
import { FaUser } from 'react-icons/fa';

const PurchaseInvoiceEntryItem = ({ item, active, isFixedAsset }) => {
  const [queryParams] = useSearchParams();
  const pageNumber = queryParams.get('page');
  const filterQueryParam = queryParams.get('filters');

  return (
    <ListGroup.Item
      as={Link}
      to={`/${
        isFixedAsset ? 'fixed-assets' : 'purchase'
      }/purchase-invoice?${createSearchParams({
        ...(filterQueryParam ? { filters: filterQueryParam } : null),
        entry: item.id,
        layout: 'column',
        ...(pageNumber ? { page: pageNumber } : null)
      })}`}
      className={`d-flex active-list-item text-decoration-none ${
        active ? 'active-three-column-tab-item' : ''
      }`}
      style={{ backgroundColor: active ? '#edf2f9' : '#fff' }}
    >
      {/* <Form.Check type="checkbox" /> */}
      <div className="ms-2 flex-fill">
        <div className="d-flex flex-row mb-1">
          {item.supplier_name && (
            <div className="d-flex flex-row mb-2">
              <FaUser />
              <h6 className="mb-0 ms-2 fw-bold">{item.supplier_name}</h6>
            </div>
          )}

          {item.is_auto && (
            <div className="ms-auto">
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip>
                    <small>auto generated</small>
                  </Tooltip>
                }
              >
                <div>
                  <Badge
                    bg="danger"
                    size="sm"
                    className="ms-2 p-1 text-uppercase"
                  >
                    <small>auto</small>
                  </Badge>
                </div>
              </OverlayTrigger>
            </div>
          )}
        </div>
        <div className="d-flex align-items-start justify-content-between">
          <h6 className="text-muted" style={{ fontSize: '.74rem' }}>
            {item.trans_date_formatted}
          </h6>
          <div className="">{item.reference}</div>
        </div>
      </div>
    </ListGroup.Item>
  );
};

PurchaseInvoiceEntryItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.bool,
  handleActive: PropTypes.func,
  isFixedAsset: PropTypes.bool
};

export default PurchaseInvoiceEntryItem;
